<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<div class="editArea">
					<div class="inBox">
						<p>선택</p>
						<button
							type="button"
							:class="{ 'btnEdit v2': true, active: 2 === type }"
							:disabled="4 === type && selected"
							@click="onRectangle"
						>
							사각형
						</button>
						<button
							type="button"
							:class="{ 'btnEdit v3': true, active: 3 === type }"
							:disabled="4 === type && selected"
							@click="onCircle"
						>
							원
						</button>
						<button
							type="button"
							:class="{ 'btnEdit v4': true, active: 4 === type }"
							:disabled="4 === type && selected"
							@click="onPolygon"
						>
							다각형
						</button>
						<button type="button" class="btn" @click="onEnd" v-show="4 === type && selected">완료</button>
					</div>
					<div class="inBox">
						<p>편집</p>
						<button
							type="button"
							:class="{ btn: true, btnLblue: 1 === type }"
							:disabled="4 === type && selected"
							@click="onSelect"
						>
							선택
						</button>
						<button type="button" class="btn" @click="onEditInfo" v-show="1 === type && selected">수정</button>
						<button type="button" class="btn" @click="onDelete" v-show="1 === type && selected">
							삭제
						</button>
					</div>
				</div>
				<button type="button" class="btnEdit v5" @click="onRemove">종료</button>
			</div>
		</div>
		<!--// titArea -->
		<!-- floorPlan -->
		<div class="floorPlan">
			<div style="position: relative; height:100%;">
				<img ref="imgFloorPlan" style="position: absolute;" :src="'/file/' + imageId" alt="" />
				<svg
					xmlns="http://www.w3.org/2000/svg"
					version="1.2"
					baseProfile="tiny"
					class="svg"
					ref="svgEditor"
					width="100%"
					height="100%"
					style="top:0px; left: 0px;"
					@mousedown.capture="onMousedown"
					@mousemove.capture="onMousemove"
					@mouseup.capture="onMouseup"
					@mouseleave="onMouseleave"
					@touchstart="onTouchstart"
					@touchmove="onTouchmove"
					@touchend="onTouchend"
				></svg>
			</div>
			<div class="btnZoomArea" style="z-index:100;">
				<div class="inBox">
					<a href="javascript:void(0)" @click="onZoomin">확대</a>
					<a href="javascript:void(0)" @click="onZoomout">축소</a>
				</div>
			</div>
		</div>
		<!--// floorPlan -->
		<!-- inner -->
		<div class="inner">
			<!-- btnBottom -->
			<div class="btnBottom">
				<button type="button" class="btn btnGray" @click="onPrev">취소</button>
				<button type="button" class="btn" :disabled="4 === type && selected" @click="onSave">저장</button>
			</div>
			<!--// btnBottom -->
		</div>
		<!--// inner -->
		<PopupRoom ref="popupRoom" :floorPlanId="this.floorPlanId"></PopupRoom>
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner } from '@/utils/cmm';
import { hideMenu } from '@/utils/cmm';
import floorplan from '@/api/floorplan';
import room from '@/api/room';
import PopupRoom from './popupRoom';

const SVG_NS = 'http://www.w3.org/2000/svg';
const HELPER_SIZE = 14;
const HELPER_HALF_SIZE = HELPER_SIZE / 2;
const COLOR_FILL = '#0c234c33';
const COLOR_STROKE = '#0c234c';

export default {
	components: { PopupRoom },
	data() {
		return {
			floorPlanId: Number(this.$route.params.id),
			// floorId: null,
			floorId: Number(this.$route.params.fId),
			// roomId: Number(this.$route.params.rId),
			imageId: null,
			selected: null,
			selectedIdx: -1,
			pointer: null,
			beginPointer: null,
			type: 0, // 0: none, 1: select, 2: rectangle, 3: circle, 4: polygon
			isDrag: false,
			image: null,
			data: {},
			deletedRooms: [],
		};
	},
	watch: {
		type(newVal, oldVal) {
			if (1 === oldVal && 1 !== newVal && this.selected) {
				this.unselected();
			}
		},
	},

	created() {
		hideMenu();
		this.init();
		window.addEventListener('scroll', this.onScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.onScroll);
	},
	methods: {
		onScroll() {
			if (window.scrollY < 100) {
				window.scroll(0, 0);
				document.body.scrollTop = 0;
			}
		},
		onSelect() {
			this.type = 1 === this.type ? 0 : 1;
		},
		onRectangle() {
			this.type = 2 === this.type ? 0 : 2;
		},
		onCircle() {
			this.type = 3 === this.type ? 0 : 3;
		},
		async onPolygon() {
			this.type = 4 === this.type ? 0 : 4;
		},
		async onEditInfo() {
			if (!this.selected) return;
			let selected = this.selected;
			let room = await this.$refs.popupRoom.select(selected.info);
			if (room) {
				Object.assign(selected.info, room);
			}
		},
		async onDelete() {
			if (!this.selected) return;
			const id = this.selected.info.id;
			if (id) {
				this.deletedRooms.push(id);
			}

			this.selected.remove();
			this.selected = null;
		},
		async onEnd() {
			await this.addintionalInfo();
			this.type = 0;
		},
		async onRemove() {
			if (!(await this.$confirm('도면을 삭제하시겠습니다?', '도면 설정 안내'))) return;
			try {
				showSpinner();
				await floorplan.remove(this.floorPlanId);
				this.goFloor();
			} catch (ex) {
				await this.$alert(`도면 삭제 에러가 발생했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async onZoomin() {
			this.zoomImgFloorPlan(1.1);
		},
		async onZoomout() {
			this.zoomImgFloorPlan(0.9);
		},
		async onSave() {
			try {
				showSpinner();
				// console.log('this.floorPlanId');
				// console.log(this.floorPlanId);
				const scale = this.image.orgWidth / this.$refs.imgFloorPlan.width;
				const offsetX = this.$refs.imgFloorPlan.offsetLeft;
				const offsetY = this.$refs.imgFloorPlan.offsetTop;

				const convX = val => Math.round((('number' === typeof val ? val : Number(val)) - offsetX) * scale);
				const convY = val => Math.round((('number' === typeof val ? val : Number(val)) - offsetY) * scale);

				const objs = this.$refs.svgEditor.querySelectorAll('g._draw');
				const data = [];
				for (let i = 0; i < objs.length; ++i) {
					const obj = objs[i].obj;
					const info = objs[i].info;
					// console.log('info');
					// console.log(info);
					switch (obj.tagName) {
						case 'rect':
							data.push({
								shapeType: 'rect',
								shapeInfo: [
									convX(obj.getAttribute('x')),
									convY(obj.getAttribute('y')),
									Math.round(Number(obj.getAttribute('width')) * scale),
									Math.round(Number(obj.getAttribute('height')) * scale),
								].join(' '),
								id: info.id == null ? '' : info.id,
								// id: this.roomId, //info.id == null ? '' : info.id,
								name: info.name,
								tenantId: info.tenantId,
							});
							break;
						case 'circle':
							data.push({
								shapeType: 'circle',
								shapeInfo: [
									convX(obj.getAttribute('cx')),
									convY(obj.getAttribute('cy')),
									Math.round(Number(obj.getAttribute('r')) * scale),
								].join(' '),
								id: info.id == null ? '' : info.id,
								name: info.name,
								tenantId: info.tenantId,
							});
							break;
						case 'polygon':
							data.push({
								shapeType: 'polygon',
								shapeInfo: obj
									.getAttribute('points')
									.split(' ')
									.map((it, i) => (i % 2 ? convY(it) : convX(it)))
									.join(' '),
								id: info.id == null ? '' : info.id,
								name: info.name,
								tenantId: info.tenantId,
							});
							break;
					}
				}
				// console.log('rooms: data');
				// console.log(data);
				// console.log('this.deletedRooms');
				// console.log(this.deletedRooms);
				await floorplan.update({ id: this.floorPlanId, rooms: data, deletedRooms: this.deletedRooms });
				// console.log('this.floorPlanId');
				// console.log(this.floorPlanId);
				// console.log('HEEEEEEEEEEEEEEEEEEEEEEEEEEER');
			} catch (ex) {
				await this.$alert(`도면 저장 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
			// console.log('HERE');
			this.goFloor();
		},
		async onPrev() {
			if (
				!(await this.$confirm('현재 작업 중인 데이터가 취소됩니다.\r\n작업 취소를 진행하시겠습니까?', '도면 설정 안내'))
			)
				return;
			this.goFloor();
		},
		goFloor() {
			this.$router.push({ name: 'floorDetail', params: { id: this.floorId } });
		},
		onMousedown(ev) {
			const { target, clientX, clientY } = ev;
			const { left, top } = this.$refs.svgEditor.getBoundingClientRect();
			const pointer = { x: clientX - left, y: clientY - top };

			this.isDrag = true;
			if (0 == this.type) {
				this.isMoving = true;
			} else if (1 === this.type) {
				if (this.$refs.svgEditor === target) {
					this.isMoving = true;
				} else if (this.selected) {
					if (this.selected.obj !== target) {
						const hIdx = this.selected.helper.indexOf(target);
						this.selectedIdx = hIdx;
						if (!~hIdx) {
							this.unselected();
						}
					}
				}
			} else if (2 === this.type) {
				const g = document.createElementNS(SVG_NS, 'g');
				g.classList.add('_draw');
				const rect = document.createElementNS(SVG_NS, 'rect');
				rect.setAttribute('x', pointer.x);
				rect.setAttribute('y', pointer.y);
				rect.setAttribute('width', 0);
				rect.setAttribute('height', 0);
				this.applyDefaultAttribute(rect);

				g.appendChild(rect);
				g.obj = rect;

				const hGroup = document.createElementNS(SVG_NS, 'g');
				hGroup.classList.add('_helper');
				hGroup.style.display = 'none';
				const helper = [];
				for (let i = 0; i < 4; ++i) {
					const h = document.createElementNS(SVG_NS, 'rect');
					h.setAttribute('x', pointer.x + HELPER_HALF_SIZE);
					h.setAttribute('y', pointer.y + HELPER_HALF_SIZE);
					this.applyHelperAttribute(h);
					hGroup.appendChild(h);
					helper.push(h);
				}

				g.appendChild(hGroup);
				g.helper = helper;

				this.appendDraw(g);
				this.selected = g;
			} else if (3 === this.type) {
				const g = document.createElementNS(SVG_NS, 'g');
				g.classList.add('_draw');
				const circle = document.createElementNS(SVG_NS, 'circle');
				circle.setAttribute('cx', pointer.x);
				circle.setAttribute('cy', pointer.y);
				circle.setAttribute('r', 0);
				this.applyDefaultAttribute(circle);

				g.appendChild(circle);
				g.obj = circle;

				const hGroup = document.createElementNS(SVG_NS, 'g');
				hGroup.classList.add('_helper');
				hGroup.style.display = 'none';
				const helper = [];
				for (let i = 0; i < 4; ++i) {
					const h = document.createElementNS(SVG_NS, 'rect');
					h.setAttribute('x', pointer.x + HELPER_HALF_SIZE);
					h.setAttribute('y', pointer.y + HELPER_HALF_SIZE);
					this.applyHelperAttribute(h);
					hGroup.appendChild(h);
					helper.push(h);
				}

				g.appendChild(hGroup);
				g.helper = helper;

				this.appendDraw(g);
				this.selected = g;
			} else if (4 === this.type) {
				if (this.selected) {
					const obj = this.selected.obj;
					const orgPoints = obj.getAttribute('points');
					const points = `${orgPoints} ${pointer.x} ${pointer.y}`;
					obj.setAttribute('points', points);
					const helper = this.selected.helper;
					const h = document.createElementNS(SVG_NS, 'rect');
					h.setAttribute('x', pointer.x - HELPER_HALF_SIZE);
					h.setAttribute('y', pointer.y - HELPER_HALF_SIZE);
					this.applyHelperAttribute(h);
					const _helper = this.selected.querySelector('g._helper');
					_helper.appendChild(h);
					helper.push(h);
				} else {
					const g = document.createElementNS(SVG_NS, 'g');
					g.classList.add('_draw');
					const polygon = document.createElementNS(SVG_NS, 'polygon');
					polygon.setAttribute('points', `${pointer.x} ${pointer.y}`);
					this.applyDefaultAttribute(polygon);

					g.appendChild(polygon);
					g.obj = polygon;

					const hGroup = document.createElementNS(SVG_NS, 'g');
					hGroup.classList.add('_helper');
					hGroup.style.display = 'none';
					const helper = [];
					const h = document.createElementNS(SVG_NS, 'rect');
					h.setAttribute('x', pointer.x - HELPER_HALF_SIZE);
					h.setAttribute('y', pointer.y - HELPER_HALF_SIZE);
					this.applyHelperAttribute(h);
					hGroup.appendChild(h);
					helper.push(h);

					g.appendChild(hGroup);
					g.helper = helper;

					this.appendDraw(g);
					this.selected = g;
				}
			}

			if (this.isMoving) {
				ev.preventDefault();
			}
			this.pointer = pointer;
			this.beginPointer = pointer;
		},
		onMousemove({ clientX, clientY }) {
			if (!this.isDrag) return;

			const { left, top } = this.$refs.svgEditor.getBoundingClientRect();
			const pointer = { x: clientX - left, y: clientY - top };
			const dx = pointer.x - this.pointer.x;
			const dy = pointer.y - this.pointer.y;

			if (0 == this.type) {
				this.moveImgFloorPlan(dx, dy);
			} else if (1 == this.type) {
				if (this.isMoving) {
					if (!this.isMoved && (0 !== dx || 0 !== dy)) {
						this.isMoved = true;
					}
					this.moveImgFloorPlan(dx, dy);
				} else if (this.selected) {
					const obj = this.selected.obj;
					switch (obj.tagName) {
						case 'rect':
							{
								let x = Number(obj.getAttribute('x'));
								let y = Number(obj.getAttribute('y'));
								let w = Number(obj.getAttribute('width'));
								let h = Number(obj.getAttribute('height'));
								switch (this.selectedIdx) {
									case 0:
										x += dx;
										y += dy;
										w -= dx;
										h -= dy;
										break;
									case 1:
										y += dy;
										w += dx;
										h -= dy;
										break;
									case 2:
										x += dx;
										w -= dx;
										h += dy;
										break;
									case 3:
										w += dx;
										h += dy;
										break;
									default:
										x += dx;
										y += dy;
										break;
								}
								this.moveRectangle(this.selected, x, y, w, h);
							}
							break;
						case 'circle':
							{
								let cx = Number(obj.getAttribute('cx'));
								let cy = Number(obj.getAttribute('cy'));
								let r = Number(obj.getAttribute('r'));
								switch (this.selectedIdx) {
									case 0:
										r -= dy;
										break;
									case 1:
										r += dx;
										break;
									case 2:
										r += dy;
										break;
									case 3:
										r -= dx;
										break;
									default:
										cx += dx;
										cy += dy;
										break;
								}
								this.moveCircle(this.selected, cx, cy, r);
							}
							break;
						case 'polygon': {
							let points = obj.getAttribute('points');
							points = points.split(' ').map(it => Number(it));
							if (~this.selectedIdx) {
								points[this.selectedIdx * 2] = pointer.x;
								points[this.selectedIdx * 2 + 1] = pointer.y;
							} else {
								for (let i = 0; i < points.length; i += 2) {
									points[i] += dx;
									points[i + 1] += dy;
								}
							}
							this.movePolygon(this.selected, points);
						}
					}
				}
			} else if (2 === this.type) {
				if (this.selected) {
					let x = Math.min(this.beginPointer.x, pointer.x);
					let y = Math.min(this.beginPointer.y, pointer.y);
					let w = Math.abs(this.beginPointer.x - pointer.x);
					let h = Math.abs(this.beginPointer.y - pointer.y);
					this.moveRectangle(this.selected, x, y, w, h);
				}
			} else if (3 === this.type) {
				if (this.selected) {
					const obj = this.selected.obj;
					let cx = Number(obj.getAttribute('cx'));
					let cy = Number(obj.getAttribute('cy'));
					let r = Number(obj.getAttribute('r'));
					this.moveCircle(this.selected, cx, cy, Math.min(r + dx, r + dy));
				}
			}

			this.pointer = pointer;
		},
		async onMouseup({ target }) {
			this.isDrag = false;

			const isSamePointer = this.pointer
				? this.beginPointer.x === this.pointer.x && this.beginPointer.y === this.pointer.y
				: true;

			if (1 === this.type) {
				const parent = target.parentNode;
				if (!this.isMoved) {
					if ('g' === parent.tagName && parent.classList.contains('_draw')) {
						if (this.selected && this.selected !== parent) {
							this.unselected();
						}
						this.selected = parent;
						const helper = this.selected.getElementsByTagName('g');
						if (helper && helper[0]) {
							helper[0].style.display = 'block';
						}
					} else if (!~this.selectedIdx) {
						this.unselected();
					}
				}
				this.selectedIdx = -1;
			} else if (4 !== this.type && !isSamePointer) {
				await this.addintionalInfo();
				// this.type = 0;
			}

			if (this.isMoving) {
				this.isMoving = false;
				this.isMoved = false;
			}
		},
		onMouseleave() {
			this.isDrag = false;
			if (this.isMoving) {
				this.isMoving = false;
				this.isMoved = false;
			}
		},
		onTouchstart(ev) {
			ev.preventDefault();

			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousedown', { clientX, clientY }));
		},
		onTouchmove(ev) {
			const { target, touches } = ev;
			const { clientX, clientY } = touches[0];

			target.dispatchEvent(new MouseEvent('mousemove', { clientX, clientY }));
		},
		onTouchend({ target }) {
			this.onMouseup({ target });
		},
		async init() {
			try {
				showSpinner();
				// console.log('this.floorPlanId init');
				// console.log(this.floorPlanId);
				let res = await floorplan.getOne(this.floorPlanId);
				// console.log('this.floorPlanId res');
				// console.log(this.res);
				if (!res.imageId) {
					await this.$alert('도면 이미지가 없는 잘못된 도면입니다.', '도면 설정 안내');
					return this.$router.go(-1);
				}

				this.floorId = res.floorId;
				this.imageId = res.imageId;
				const sizeImg = await this.sizeOfImage(res.imageId);
				this.image = { orgWidth: sizeImg.width, orgHeight: sizeImg.height };
			} catch (ex) {
				await this.$alert(`도면 상세 정보 조회 에러가 발생했습니다. 도면수정을 할 수 없습니다.\r\n${ex.message}`);
				// return this.$router.go(-1);
			} finally {
				hideSpinner();
			}

			this.reload();
		},
		sizeOfImage(imageId) {
			return new Promise((resolve, reject) => {
				let img = new Image();
				img.onload = function() {
					resolve({ width: this.width, height: this.height });
				};
				img.onerror = function() {
					reject({ message: `도면 이미지 로딩 실패: ${this.src}` });
				};
				img.src = `/file/${imageId}`;
			});
		},
		async reload() {
			try {
				showSpinner();

				let res = await room.get({ floorPlanId: this.floorPlanId });
				let rooms = res.data || [];
				rooms
					.filter(it => it.shapeType)
					.forEach(it => {
						const vals = it.shapeInfo.split(' ').map(it => Number(it));
						switch (it.shapeType) {
							case 'rect':
								{
									const g = document.createElementNS(SVG_NS, 'g');
									g.classList.add('_draw');
									const rect = document.createElementNS(SVG_NS, 'rect');
									rect.setAttribute('x', vals[0]);
									rect.setAttribute('y', vals[1]);
									rect.setAttribute('width', vals[2]);
									rect.setAttribute('height', vals[3]);
									this.applyDefaultAttribute(rect);

									g.appendChild(rect);
									g.obj = rect;

									const hGroup = document.createElementNS(SVG_NS, 'g');
									hGroup.classList.add('_helper');
									hGroup.style.display = 'none';

									const helper = [];
									let h;
									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] + vals[2] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] + vals[3] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] + vals[2] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] + vals[3] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									g.appendChild(hGroup);
									g.helper = helper;
									g.info = { id: it.id, name: it.name, tenantId: it.tenantId || '' };

									this.appendDraw(g);
								}
								break;
							case 'circle':
								{
									const g = document.createElementNS(SVG_NS, 'g');
									g.classList.add('_draw');
									const circle = document.createElementNS(SVG_NS, 'circle');
									circle.setAttribute('cx', vals[0]);
									circle.setAttribute('cy', vals[1]);
									circle.setAttribute('r', vals[2]);
									this.applyDefaultAttribute(circle);

									g.appendChild(circle);
									g.obj = circle;

									const hGroup = document.createElementNS(SVG_NS, 'g');
									hGroup.classList.add('_helper');
									hGroup.style.display = 'none';

									const helper = [];
									let h;
									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] - vals[2] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] + vals[2] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] + vals[2] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									h = document.createElementNS(SVG_NS, 'rect');
									h.setAttribute('x', vals[0] - vals[2] - HELPER_HALF_SIZE);
									h.setAttribute('y', vals[1] - HELPER_HALF_SIZE);
									this.applyHelperAttribute(h);
									hGroup.appendChild(h);
									helper.push(h);

									g.appendChild(hGroup);
									g.helper = helper;
									g.info = { id: it.id, name: it.name, tenantId: it.tenantId || '' };

									this.appendDraw(g);
								}
								break;
							case 'polygon':
								{
									const g = document.createElementNS(SVG_NS, 'g');
									g.classList.add('_draw');
									const rect = document.createElementNS(SVG_NS, 'polygon');
									rect.setAttribute('points', vals.join(' '));
									this.applyDefaultAttribute(rect);

									g.appendChild(rect);
									g.obj = rect;

									const hGroup = document.createElementNS(SVG_NS, 'g');
									hGroup.classList.add('_helper');
									hGroup.style.display = 'none';

									const helper = [];
									let h;
									for (let i = 0; i < vals.length; i += 2) {
										h = document.createElementNS(SVG_NS, 'rect');
										h.setAttribute('x', vals[i] - HELPER_HALF_SIZE);
										h.setAttribute('y', vals[i + 1] - HELPER_HALF_SIZE);
										this.applyHelperAttribute(h);
										hGroup.appendChild(h);
										helper.push(h);

										g.appendChild(hGroup);
									}

									g.helper = helper;
									g.info = { id: it.id, name: it.name, tenantId: it.tenantId || '' };

									this.appendDraw(g);
								}
								break;
						}
					});

				// const { width, height } = this.$refs.svgEditor.getBoundingClientRect();
				// const offsetX = (width - this.image.orgWidth) * 0.5;
				// const offsetY = (height - this.image.orgHeight) * 0.5;
				// console.log('offsetX');
				// console.log(offsetX);
				// console.log('offsetY');
				// console.log(offsetY);
				// this.imageX = offsetX;
				// this.imageY = offsetY;
				// this.moveImgFloorPlan(offsetX, offsetY);

				const imgFloorPlan = this.$refs.imgFloorPlan;
				if (imgFloorPlan) {
					const currentTop = imgFloorPlan.style.top;
					const currentLeft = imgFloorPlan.style.left;
					// console.log('currentTop');
					// console.log(currentTop);
					// console.log('currentLeft');
					// console.log(currentLeft);
					imgFloorPlan.style.top = currentTop || '0px';
					imgFloorPlan.style.left = currentLeft || '0px';
				}
			} catch (ex) {
				await this.$alert(`도면 조회 실패했습니다.\r\n${ex.message}`);
			} finally {
				hideSpinner();
			}
		},
		async addintionalInfo() {
			if (!this.selected) return;
			let selected = this.selected;
			let room = await this.$refs.popupRoom.select();
			if (!room) {
				selected.remove();
			} else {
				selected.info = room;
			}
			this.selected = null;
		},
		appendDraw(obj) {
			this.$refs.svgEditor.appendChild(obj);
		},
		zoomImgFloorPlan(scale) {
			const width = this.$refs.imgFloorPlan.width;
			const height = this.$refs.imgFloorPlan.height;
			this.$refs.imgFloorPlan.width = width * scale;
			this.$refs.imgFloorPlan.height = height * scale;

			const offsetX = this.$refs.imgFloorPlan.offsetLeft;
			const offsetY = this.$refs.imgFloorPlan.offsetTop;

			const { width: width2, height: height2 } = this.$refs.svgEditor.getBoundingClientRect();
			const offsetX2 = (width2 - offsetX * 2) * (scale - 1) * 0.5;
			const offsetY2 = (height2 - offsetY * 2) * (scale - 1) * 0.5;

			let imgFloorPlanX = this.$refs.imgFloorPlan.offsetLeft - offsetX2;
			let imgFloorPlanY = this.$refs.imgFloorPlan.offsetTop - offsetY2;
			this.$refs.imgFloorPlan.style.left = `${imgFloorPlanX}px`;
			this.$refs.imgFloorPlan.style.top = `${imgFloorPlanY}px`;

			const objs = this.$refs.svgEditor.querySelectorAll('g._draw');
			for (let i = 0; i < objs.length; ++i) {
				const obj = objs[i].obj;
				if (!obj) continue;
				switch (obj.tagName) {
					case 'rect':
						{
							let x = (Number(obj.getAttribute('x')) - offsetX) * scale + offsetX - offsetX2;
							let y = (Number(obj.getAttribute('y')) - offsetY) * scale + offsetY - offsetY2;
							let w = Number(obj.getAttribute('width')) * scale;
							let h = Number(obj.getAttribute('height')) * scale;
							this.moveRectangle(objs[i], x, y, w, h);
						}
						break;
					case 'circle':
						{
							let cx = (Number(obj.getAttribute('cx')) - offsetX) * scale + offsetX - offsetX2;
							let cy = (Number(obj.getAttribute('cy')) - offsetY) * scale + offsetY - offsetY2;
							let r = Number(obj.getAttribute('r')) * scale;
							this.moveCircle(objs[i], cx, cy, r);
						}
						break;
					case 'polygon':
						{
							let points = obj
								.getAttribute('points')
								.split(' ')
								.map(it => Number(it));
							for (let i = 0; i < points.length; i += 2) {
								points[i] = (points[i] - offsetX) * scale + offsetX - offsetX2;
								points[i + 1] = (points[i + 1] - offsetY) * scale + offsetY - offsetY2;
							}
							this.movePolygon(objs[i], points);
						}
						break;
				}
			}
		},
		moveImgFloorPlan(dx, dy) {
			let imgFloorPlanX = this.$refs.imgFloorPlan.offsetLeft + dx;
			let imgFloorPlanY = this.$refs.imgFloorPlan.offsetTop + dy;
			// console.log('imgFloorPlanX');
			// console.log(imgFloorPlanX);
			// console.log('imgFloorPlanY');
			// console.log(imgFloorPlanY);
			this.$refs.imgFloorPlan.style.left = `${imgFloorPlanX}px`;
			this.$refs.imgFloorPlan.style.top = `${imgFloorPlanY}px`;

			const objs = this.$refs.svgEditor.querySelectorAll('g._draw');
			for (let i = 0; i < objs.length; ++i) {
				const obj = objs[i].obj;
				if (!obj) continue;
				switch (obj.tagName) {
					case 'rect':
						{
							let x = Number(obj.getAttribute('x'));
							let y = Number(obj.getAttribute('y'));
							let w = Number(obj.getAttribute('width'));
							let h = Number(obj.getAttribute('height'));
							this.moveRectangle(objs[i], x + dx, y + dy, w, h);
						}
						break;
					case 'circle':
						{
							let cx = Number(obj.getAttribute('cx'));
							let cy = Number(obj.getAttribute('cy'));
							let r = Number(obj.getAttribute('r'));
							this.moveCircle(objs[i], cx + dx, cy + dy, r);
						}
						break;
					case 'polygon':
						{
							let points = obj
								.getAttribute('points')
								.split(' ')
								.map(it => Number(it));
							for (let i = 0; i < points.length; i += 2) {
								points[i] += dx;
								points[i + 1] += dy;
							}
							this.movePolygon(objs[i], points);
						}
						break;
				}
			}
		},
		moveRectangle(obj, x, y, w, h) {
			const helper = obj.helper;
			obj = obj.obj;
			if (!obj) return;

			obj.setAttribute('x', x);
			obj.setAttribute('y', y);

			w = Math.max(w, 1);
			obj.setAttribute('width', w);

			h = Math.max(h, 1);
			obj.setAttribute('height', h);

			if (helper) {
				helper[0].setAttribute('x', x - HELPER_HALF_SIZE);
				helper[0].setAttribute('y', y - HELPER_HALF_SIZE);
				helper[1].setAttribute('x', x + w - HELPER_HALF_SIZE);
				helper[1].setAttribute('y', y - HELPER_HALF_SIZE);
				helper[2].setAttribute('x', x - HELPER_HALF_SIZE);
				helper[2].setAttribute('y', y + h - HELPER_HALF_SIZE);
				helper[3].setAttribute('x', x + w - HELPER_HALF_SIZE);
				helper[3].setAttribute('y', y + h - HELPER_HALF_SIZE);
			}
		},
		moveCircle(obj, cx, cy, r) {
			const helper = obj.helper;
			obj = obj.obj;
			if (!obj) return;

			obj.setAttribute('cx', cx);
			obj.setAttribute('cy', cy);

			r = Math.max(r, 0);
			obj.setAttribute('r', r);

			if (helper) {
				helper[0].setAttribute('x', cx - HELPER_HALF_SIZE);
				helper[0].setAttribute('y', cy - r - HELPER_HALF_SIZE);
				helper[1].setAttribute('x', cx + r - HELPER_HALF_SIZE);
				helper[1].setAttribute('y', cy - HELPER_HALF_SIZE);
				helper[2].setAttribute('x', cx - HELPER_HALF_SIZE);
				helper[2].setAttribute('y', cy + r - HELPER_HALF_SIZE);
				helper[3].setAttribute('x', cx - r - HELPER_HALF_SIZE);
				helper[3].setAttribute('y', cy - HELPER_HALF_SIZE);
			}
		},
		movePolygon(obj, points) {
			const helper = obj.helper;
			obj = obj.obj;
			if (!obj) return;

			obj.setAttribute('points', points.join(' '));

			if (helper) {
				for (let i = 0; i < helper.length; ++i) {
					helper[i].setAttribute('x', points[i * 2] - HELPER_HALF_SIZE);
					helper[i].setAttribute('y', points[i * 2 + 1] - HELPER_HALF_SIZE);
				}
			}
		},
		unselected() {
			if (!this.selected) return;
			const helper = this.selected.getElementsByTagName('g');
			if (helper && helper[0]) {
				helper[0].style.display = 'none';
			}
			this.selected = null;
			this.selectedIdx = -1;
		},
		applyDefaultAttribute(el) {
			el.setAttribute('fill', COLOR_FILL);
			el.setAttribute('stroke', COLOR_STROKE);
			el.setAttribute('stroke-width', 1);
		},
		applyHelperAttribute(el) {
			el.setAttribute('width', HELPER_SIZE);
			el.setAttribute('height', HELPER_SIZE);
			el.setAttribute('fill', COLOR_STROKE);
		},
	},
};
</script>

<style scoped>
svg:hover {
	stroke: red;
}
</style>
