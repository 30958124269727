<template>
	<!-- 팝업 -->
	<div class="layerPop alert on" v-if="dialog">
		<div class="inner" style="width:320px">
			<strong class="tit">도면 설정 안내</strong>
			<div class="cont">
				<div class="inputTable">
					<table>
						<caption>
							실별, 사용자 항목으로 구성된 표
						</caption>
						<colgroup>
							<col style="width:54px" />
							<col style="width:auto" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row">실별</th>
								<td>
									<input
										type="text"
										title="실별 입력"
										placeholder="실별을 입력해주세요."
										required="required"
										v-model.trim="data.name"
										maxlength="254"
									/>
								</td>
							</tr>
							<!-- <tr>
								<th scope="row">사용자</th>
								<td>
									<input
										type="text"
										title="사용자 입력"
										placeholder="사용자명을 입력해주세요."
										required="required"
										v-model.trim="data.user"
										maxlength="254"
									/>
								</td>
							</tr> -->
							<tr>
								<th scope="row">입주사</th>
								<td>
									<select title="입주사 입력" v-model="data.tenantId">
										<option value="">입주사를 선택해주세요.</option>
										<option v-for="(tenant, index) in tenantList" :key="index" :value="tenant.id">
											{{ tenant.name }}
										</option>
									</select>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- btnArea -->
			<div class="btnArea">
				<button type="button" class="btn btnGray" @click="onClose(undefined)">
					취소
				</button>
				<button type="button" class="btn" @click="onSave">저장</button>
			</div>
			<!--// btnArea -->
		</div>
	</div>
	<!-- 팝업 -->
</template>
<script>
import tenant from '@/api/tenant';
export default {
	props: {
		value: {},
		floorPlanId: {
			type: Number,
		},
	},
	data() {
		return {
			dialog: false,
			popupResolve: null,
			data: { name: '', tenantId: '' },
			tenantList: [],
		};
	},
	mounted() {
		this.onTenantGet();
	},
	methods: {
		async onSave() {
			let res = await this.validate();
			if (!res) return;
			this.onClose(this.data);
		},
		onClose(value) {
			this.dialog = false;
			this.data = { name: '', tenantId: '' };

			if (this.popupResolve) {
				this.popupResolve(value);
				this.popupResolve = null;
			}
		},
		select(value) {
			if (this.popupResolve) {
				return null;
			}

			if (value) {
				this.data = value;
			}

			this.dialog = true;
			return new Promise(resolve => (this.popupResolve = resolve));
		},
		async validate() {
			let data = this.data;
			if (!data.name) {
				return await this.$alert('실별을 입력해주세요.', '도면 설정 안내');
			}
			// if (!data.tenantId) {
			// 	return await this.$alert('입주사를 선택해주세요.', '도면 설정 안내');
			// }

			// if (!data.user) {
			// 	return await this.$alert('사용자명을 입력해주세요.', '도면 설정 안내');
			// }

			return true;
		},

		async onTenantGet() {
			let params = {
				id: this.floorPlanId,
				orderBy: 'createDate',
			};
			try {
				const res = await tenant.getByfloorPlanId(params);
				this.total = res.total;
				if (this.total > 0) {
					this.tenantList = res.data;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
	},
};
</script>

<style scoped></style>
